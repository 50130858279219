import {
  Button,
  Dropdown,
  Form,
  Icon,
  Menu,
  message,
  Modal,
  notification,
  Popconfirm,
  Tag,
  Tooltip,
} from "antd";
import copy from "copy-to-clipboard";
import QrCode from "../../../components/QrCode";
import EditDrawer, {
  contractModelOptions,
} from "../../../components/StationEditDrawer/EditDrawer";
import { getCompanyCurrentUser } from "../../../services/auth";
import CardStatusStations from "../../../components/CardStatusStations";
import StatusWithDot from "../../../components/StatusWithDot";
import moment from "moment-timezone";
import React, { Component, Fragment } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import GenerateFilter from "../../../components/GenerateFilter";
import TablePagination, {
  paginationInitConfig,
} from "../../../components/TablePagination";
import { Show } from "../../../contexts/PermissionContext";
import withPermission from "../../../contexts/PermissionContext/withPermission";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import api from "../../../services/api";
import { getStationsOptionsBySelectedCompanies } from "../../../utils/getOptions";
import generateFields from "./fields";
import "./index.css";
import { formatters } from "../../../utils/formatters";
import { sorter } from "../../../utils/sorter";
import ShareStation from "../../../components/ShareStation";
import {
  LINK_BRANCH_IO_MOBILIZE,
  LINK_BRANCH_IO_SHELL,
  LINK_BRANCH_IO_BYD,
  LINK_BRANCH_IO_BRS,
  getShareStationLink,
} from "../../../constants/branch-io-links";
import {
  MELI_MOOCA,
  MOBILIZE,
  NEO,
  NEOCHARGE,
  SHELL,
  BYD,
  BYD_TEST,
  BRS,
} from "../../../config";
import ButtonCsvDownload from "../../../components/ButtonCsvDownload";
class ListConnectedStations extends Component {
  static contextType = FreireContext;

  state = {
    name: "seila",
    loadingTable: false,
    visibleModalQrcode: false,
    data: [],
    station: {},
    stationQrCode: "",
    companys: [],
    selectedCompanies: [],
    selectedStations: [],
    stationsOptions: [],
    companyOptions: [],
    companyID: "",
    visibleEditDrawer: false,
    loadingEditDrawer: false,
    loadingUpkeepStatus: false,
    upkeepValue: false,
    stationsFilter: [],
    allSelectStations: [],
    companies: [],
    dataExcel: [],
    dateFiltrada: [],
    totalDateFiltrada: 0,
    csvLoading: false,
    cardFilter: undefined,
    pagination: paginationInitConfig,
    visibleShareStation: "",
    shareStationID: "",
  };

  constructor(props) {
    super(props);
    this.CardStatusStations = React.createRef();
    this.CSVLinkRef = React.createRef();
  }

  async setCompanyID() {
    try {
      const company = await getCompanyCurrentUser();
      if (company && company.id) {
        this.setState({ companyID: company.id });
      }
    } catch (error) {}
  }

  componentDidMount() {
    this.getStations();
    this.setCompanyID();
    this.getAllSelectStations();
    this.getAllCompanies();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.selectedCompanies !== this.state.selectedCompanies) {
      const stationsOptions = getStationsOptionsBySelectedCompanies(
        this.state.allSelectStations,
        this.state.selectedCompanies
      );

      this.setState({ stationsOptions });
    }
  }

  async getAllSelectStations() {
    const { data: allSelectStations } = await api.get(
      `select/connected_stations`
    );
    this.setState({
      allSelectStations,
      stationsOptions: getStationsOptionsBySelectedCompanies(
        allSelectStations,
        this.state.selectedCompanies
      ),
    });
  }

  async getAllCompanies() {
    const { data } = await api.get("company");

    const companies = sorter(data, "companyName");

    const companiesNotHolding = companies.filter((company) => !company.holding);

    this.setState({
      companies: companiesNotHolding,
      companyOptions: companies.map((company) => ({
        label: company.companyName,
        value: company._id,
      })),
    });
  }

  async getStations(body = {}) {
    try {
      this.setState({ loadingTable: true }, async () => {
        const {
          data: { docs, totalDocs },
        } = await api.post(`connected_stations`, {
          ...(this.state.cardFilter && { cardFilter: this.state.cardFilter }),
          ...body,
          ...this.state.pagination,
        });

        this.setState({
          dateFiltrada: docs,
          totalDateFiltrada: totalDocs,
          loadingTable: false,
          selectedStations: body.station_ids,
        });
      });
    } catch (error) {
      this.setState({ loadingTable: false });
    }
  }

  showModalQrcode = (stationQrCode) => {
    this.setState({ stationQrCode, visibleModalQrcode: true });
  };

  copyShareLinkToClipboard = async (link, stationID) => {
    const { freire } = this.context;
    const shareLink = getShareStationLink({ link, stationID });
    copy(shareLink);

    notification.success({
      message: freire(texts.ALL_RIGHT),
      description: freire(texts.SHARE_LINK_COPIED),
    });
  };

  showModalShareStation = (shareStationID) => {
    if ([NEO, NEOCHARGE, MELI_MOOCA].includes(this.state.companyID)) {
      return;
    } else if (this.state.companyID === MOBILIZE) {
      this.copyShareLinkToClipboard(LINK_BRANCH_IO_MOBILIZE, shareStationID);
      return;
    } else if (this.state.companyID === SHELL) {
      this.copyShareLinkToClipboard(LINK_BRANCH_IO_SHELL, shareStationID);
      return;
    } else if ([BYD, BYD_TEST].includes(this.state.companyID)) {
      this.copyShareLinkToClipboard(LINK_BRANCH_IO_BYD, shareStationID);
      return;
    } else if (this.state.companyID === BRS) {
      this.copyShareLinkToClipboard(LINK_BRANCH_IO_BRS, shareStationID);
      return;
    }
    this.setState({ shareStationID, visibleShareStation: true });
  };

  openDrawer = (station) => {
    if (typeof station.payment != "object") {
      station.payment = {};
    }
    if (typeof station.paymentCharge != "object") {
      station.paymentCharge = {};
    }

    this.setState({ visibleEditDrawer: true, station });
  };

  closeDrawer = () => {
    this.setState({ visibleEditDrawer: false, station: {} });
  };

  warningDescription = (description) => {
    return (
      <>
        {description[0]}
        {description.length > 1 && (
          <ul>
            {description.slice(1).map((desc, index) => (
              <li key={index}>{desc}</li>
            ))}
          </ul>
        )}
      </>
    );
  };

  onSalveStation = async (payload = {}) => {
    const { freire } = this.context;

    this.setState({ loadingEditDrawer: true }, async () => {
      try {
        if (this.state.station._id) {
          if (payload.plugsConcurrently === undefined)
            delete payload.plugsConcurrently;

          const { data } = await api.put(
            `stations/${this.state.station._id}`,
            payload
          );

          this.setState({
            loadingEditDrawer: false,
            visibleEditDrawer: false,
          });

          if (data.warning) {
            notification.warning({
              message: data.message,
              description: this.warningDescription(data.description),
              duration: 0,
            });
          } else {
            notification.success({
              message: data.message,
              description: data.description[0],
            });
          }
          this.getStations();
        }
      } catch (error) {
        const {
          response: {
            data: { message },
          },
        } = error;

        this.setState({ loadingEditDrawer: false });

        notification.error({
          message: freire(texts.WENT_WRONG),
          description: message || error.message,
        });
      }
    });
  };

  onDeleteStation = async (data) => {
    const { freire } = this.context;

    try {
      if (data._id) {
        await api.delete(`stations/${data._id}`);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.STATION_DELETED),
        });

        this.getStations();
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message || error.message,
      });
    }
  };

  filter = (byButton = true) => {
    this.props.form.validateFields((err, values) => {
      if (values.station_ids?.length === this.state.stationsOptions.length) {
        values.station_ids = undefined;
      }
      if (values.company_ids?.length === this.state.companies.length) {
        values.company_ids = undefined;
      }
      if (!err) {
        this.getStations({
          ...values,
          ...{ cardFilter: byButton ? undefined : this.state.cardFilter },
        });
      }
    });

    if (byButton) {
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: 1 },
      }));
    }
  };

  clearFilter = () => {
    this.props.form.resetFields();
    this.setState({ selectedCompanies: [] });
  };

  goNew = () => {
    this.props.history.push("/dashboard/new_station_connected");
  };

  goBatchOperations = () => {
    this.props.history.push("/dashboard/batch-operation");
  };

  onChange(companyID, value) {
    var { station } = this.state;
    station.companyID = companyID;
    this.setState({ station });
  }

  formatarMoeda(valor) {
    valor = valor.replace(/\D/g, "");
    valor = String(Number(valor));
    var len = valor.length;
    if (1 === len) valor = valor.replace(/(\d)/, "0,0$1");
    else if (2 === len) valor = valor.replace(/(\d)/, "0,$1");
    else if (len > 2) {
      valor = valor.replace(/(\d{2})$/, ",$1");
      if (len > 5) {
        var x = len - 5,
          er = new RegExp("(\\d{" + x + "})(\\d)");
        valor = valor.replace(er, "$1.$2");
      }
    }
    return valor;
  }

  async downloadExcel() {
    const { freire } = this.context;

    try {
      this.setState({ csvLoading: true });
      const selectedCompanies = this.state.selectedCompanies;
      const selectedStations = this.state.selectedStations;
      const types = await this.getStationTypes();
      const models = await this.getStationModels();
      const locals = await this.getStationLocals();
      const { hasPermission } = this.props;
      const canStationContractModel = hasPermission(
        "edit-station-contract-model"
      );
      const contractModelHeaders = canStationContractModel
        ? [
            { label: freire(texts.FIRST_PAYMENT), key: "firstPaymentAt" },
            { label: freire(texts.START_CONTRACT), key: "contractStartedAt" },
            { label: freire(texts.END_CONTRACT), key: "contractEndedAt" },
            { label: freire(texts.START_OPERATION), key: "operationStartedAt" },
            { label: freire(texts.INSTALL_DATE), key: "installedAt" },
            {
              label: freire(texts.REMUNERATION_MEDIA),
              key: "remunerationMedia",
            },
            {
              label: freire(texts.REMUNERATION_ENERGY),
              key: "remunerationEnergy",
            },
            {
              label: freire(texts.CONTRACT_MODEL_LOWER_CASE),
              key: "contractModel",
            },
            {
              label: freire(texts.PARTNER_INSTALLER),
              key: "partnerInstallerName",
            },
            { label: freire(texts.TUPI_INSTALLER), key: "tupiInstall" },
            { label: freire(texts.TUPI_CHARGER), key: "tupiPlug" },
            { label: freire(texts.POWERS), key: "power" },
            { label: freire(texts.PRIVATE_STATION), key: "private" },
          ]
        : [];

      const {
        data: { docs },
      } = await api.post(`connected_stations`, {
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
        ...(this.state.cardFilter && { cardFilter: this.state.cardFilter }),
        ...(selectedCompanies?.length > 0 && {
          company_ids: selectedCompanies,
        }),
        ...(selectedStations?.length > 0 && {
          station_ids: selectedStations,
        }),
      });

      const dataExcel = docs.map((station) => {
        if (station?.stationTypeID) {
          const type = types?.find((t) => t._id === station?.stationTypeID);
          station.stationType = type?.name || "----";
        }
        if (station?.stationModelID) {
          const model = models?.find((m) => m._id === station?.stationModelID);
          station.stationModel = model?.name || "----";
        }
        if (station?.stationLocalID) {
          const local = locals?.find((l) => l._id === station?.stationLocalID);
          station.stationLocal = local?.name || "----";
        }
        const remunerationMediaPercentage = {};
        station.splitRemunerationMedia?.forEach(
          ({ receiver, percentage, id }) => {
            const label = `Remuneração ${receiver}`;
            if (!contractModelHeaders.find((r) => r.label === label)) {
              contractModelHeaders.push({
                key: id,
                label: label,
              });
            }
            remunerationMediaPercentage[id] = percentage;
          }
        );
        return {
          ...station,
          address2: {
            city: station?.address2?.city || "----",
            state: station?.address2?.state || "----",
          },
          companyName: station?.company?.companyName || "----",
          chargeTotal: station?.chargeTotal
            ? `${station.chargeTotal.toLocaleString(freire.userLanguage, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} kWh`
            : "----",
          plugs: station?.plugs?.length ? station.plugs.join(", ") : "----",
          payment: {
            ...station.payment,
            enabled: station?.payment?.enabled
              ? freire(texts.YES)
              : freire(texts.NO),
            value: formatters.currency(
              freire.userLanguage,
              station.currency
            )(station.payment.value / 100),
          },
          paymentCharge: {
            ...station.paymentCharge,
            enabled: station?.paymentCharge?.enabled
              ? freire(texts.YES)
              : freire(texts.NO),
            value: formatters.currency(
              freire.userLanguage,
              station.currency
            )(station.paymentCharge.value / 100),
          },
          connectedDate: station?.connectedDate
            ? moment(station.connectedDate).format("L LTS")
            : "----",
          power: station?.power
            ? `${station.power.toLocaleString(freire.userLanguage, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} kW`
            : "----",
          private: station?.private ? freire(texts.YES) : freire(texts.NO),
          ...(canStationContractModel
            ? {
                firstPaymentAt: station?.firstPaymentAt
                  ? moment(station.firstPaymentAt).format("L LTS")
                  : "----",
                contractStartedAt: station?.contractStartedAt
                  ? moment(station.contractStartedAt).format("L LTS")
                  : "----",
                contractEndedAt: station?.contractEndedAt
                  ? moment(station.contractEndedAt).format("L LTS")
                  : "----",
                operationStartedAt: station?.operationStartedAt
                  ? moment(station.operationStartedAt).format("L LTS")
                  : "----",
                installedAt: station?.installedAt
                  ? moment(station.installedAt).format("L LTS")
                  : "----",
                remunerationMedia:
                  station.remunerationMedia === false
                    ? freire(texts.NO)
                    : station.remunerationMedia === true
                    ? freire(texts.YES)
                    : "----",
                remunerationEnergy:
                  station.remunerationEnergy === false
                    ? freire(texts.NO)
                    : station.remunerationEnergy === true
                    ? freire(texts.YES)
                    : "----",
                contractModel:
                  contractModelOptions(freire).find(
                    ({ value }) => value === station.contractModel
                  )?.label || "----",
                partnerInstallerName: station.partnerInstallerName,
                tupiInstall:
                  station.tupiInstall === false
                    ? freire(texts.NO)
                    : station.tupiInstall === true
                    ? freire(texts.YES)
                    : "----",
                tupiPlug:
                  station.tupiPlug === false
                    ? freire(texts.NO)
                    : station.tupiPlug === true
                    ? freire(texts.YES)
                    : "----",
                ...remunerationMediaPercentage,
              }
            : {}),
        };
      });
      this.setState({ dataExcel }, () => {
        setTimeout(() => {
          if (this.CSVLinkRef.current) {
            this.CSVLinkRef.current.link.click();
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: freire(texts.ERROR_DOWNLOAD_FILE),
            });
          }
        }, 500);
      });

      this.setState({
        headers: [
          ...this.getHeader(),
          ...(canStationContractModel ? contractModelHeaders : []),
        ],
        csvLoading: false,
      });
    } catch (error) {
      this.setState({ csvLoading: false });
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GENERATE_FILE),
      });
    }
  }

  async getStationLocals() {
    const { freire } = this.context;

    try {
      const { data } = await api.get(`station-locals`);
      return data;
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_STATIONS_LOCATIONS),
      });
    }
  }

  async getStationModels() {
    const { freire } = this.context;

    try {
      const { data } = await api.get(`station-models`);
      return data;
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_STATIONS_MODELS),
      });
    }
  }

  async getStationTypes() {
    const { freire } = this.context;

    try {
      const { data } = await api.get(`station-types`);
      return data;
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_STATIONS_TYPES),
      });
    }
  }

  async onConfirmUpkeepStatus() {
    const { freire } = this.context;
    try {
      this.setState({ loadingUpkeepStatus: true });
      await api.patch(`/stations/status/${this.state.station.stationID}`, {
        upkeep: !this.state.station.upkeep,
      });

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.STATION_UPKEEP(this.state.station.upkeep)),
      });

      this.setState(
        {
          station: {},
          cardFilter: undefined,
        },
        () => {
          this.getStations();
          this.CardStatusStations.current.getData();
        }
      );
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_UPKEEP),
      });
    } finally {
      this.setState({ loadingUpkeepStatus: false });
    }
  }

  getColumns() {
    const { freire } = this.context;

    return [
      {
        title: <span>{freire(texts.NAME)}</span>,
        dataIndex: "name",
        key: "name",
        render: (name, data) => (
          <>
            <span
              style={{ fontWeight: "bold", fontSize: 14, color: "#595959" }}
            >
              {name}
            </span>
            <br />
            <Tooltip placement="right" title={freire(texts.COPY)}>
              <span
                className="stationIDText"
                onClick={() => {
                  copy(String(data.stationID));
                  message.info(freire(texts.COPIED));
                }}
              >
                {data.stationID}
              </span>
            </Tooltip>
            <br />
            <Tooltip placement="right" title={freire(texts.STATION_CODE)}>
              <span
                className="stationIDText"
                onClick={() => {
                  copy(String(data.code));
                  message.info(freire(texts.COPIED));
                }}
              >
                {data.code}
              </span>
            </Tooltip>
          </>
        ),
      },
      {
        title: freire(texts.STATUS),
        dataIndex: "connectedPlugs",
        key: "connectedPlugs",
        width: 200,
        render: (connectedPlugs, data) => {
          return (
            <div style={{ flexDirection: "column" }}>
              {connectedPlugs.length > 0
                ? connectedPlugs.map((connector) => (
                    <div
                      key={connector.connectorID}
                      style={{ marginTop: 8, marginBottom: 8 }}
                    >
                      <StatusWithDot
                        status={connector.stateName}
                        suffixText={`ID ${connector.connectorID}`}
                        onPress={(stateName) => {
                          this.setState({ cardFilter: stateName }, () => {
                            this.getStations();
                          });
                        }}
                        zendeskTicketID={data.zendeskTicketID || null}
                        hideTooltip
                        table
                      />
                    </div>
                  ))
                : freire(texts.NO_HAVE)}
              {data.upkeep && (
                <p style={{ marginLeft: 17, fontWeight: 14, color: "#F40000" }}>
                  {freire(texts.UPKEEP)}
                </p>
              )}
            </div>
          );
        },
      },
      {
        title: freire(texts.ADDRESS),
        dataIndex: "address",
        key: "address",
      },
      {
        title: freire(texts.TOTAL_CHARGED),
        dataIndex: "chargeTotal",
        key: "chargeTotal",
        render: (chargeTotal) => {
          return (
            <span>
              {chargeTotal
                ? `${chargeTotal.toLocaleString(freire.userLanguage, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} kWh`
                : "---"}
            </span>
          );
        },
      },
      {
        title: freire(texts.POWERS),
        dataIndex: "power",
        key: "power",
        render: (power) => {
          return (
            <span>
              {power
                ? `${power.toLocaleString(freire.userLanguage, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} kW`
                : "---"}
            </span>
          );
        },
      },
      {
        title: freire(texts.DISABLED),
        dataIndex: "disabled",
        key: "disabled",
        render: (disabled) => <Icon type={disabled ? "check" : "close"} />,
      },
      {
        title: freire(texts.VISIBILITY),
        dataIndex: "visibility",
        key: "visibility",
        render: (visibility) => {
          if (visibility === "none") {
            return <Tag color="red">{freire(texts.HIDDEN)}</Tag>;
          }
          if (visibility === "groups") {
            return <Tag color="blue">{freire(texts.GROUPS)}</Tag>;
          }

          return <Tag color="green">{freire(texts.ALL)}</Tag>;
        },
      },
      {
        title: freire(texts.PLUGS),
        dataIndex: "plugs",
        key: "plugs",
        render: (plugs) => (
          <span>
            {plugs.map((plug) => (
              <Tag key={plug}>{plug}</Tag>
            ))}
          </span>
        ),
      },
      {
        title: `${freire(texts.PAYMENT)} (${freire(texts.RESERVE)})`,
        dataIndex: "payment",
        key: "payment",
        render: (payment, station) => (
          <span>
            {payment && payment.enabled ? (
              <Fragment>
                <Tag color="blue">{freire(texts.RESERVE)}</Tag>
                <br />
                <Tag style={{ marginTop: 5 }} color="green">
                  {formatters.currency(
                    freire.userLanguage,
                    station.currency
                  )(payment.value / 100)}
                </Tag>
              </Fragment>
            ) : (
              <Icon type={"close"} />
            )}
          </span>
        ),
      },
      {
        title: `${freire(texts.PAYMENT)} (${freire(texts.CHARGE)})`,
        dataIndex: "paymentCharge",
        key: "paymentCharge",
        render: (paymentCharge, station) => (
          <span>
            {paymentCharge && paymentCharge.enabled ? (
              <Fragment>
                <Tag color="blue">{paymentCharge.method}</Tag>
                <br />
                <Tag style={{ marginTop: 5 }} color="green">
                  {formatters.currency(
                    freire.userLanguage,
                    station.currency
                  )(paymentCharge.value / 100)}
                </Tag>
              </Fragment>
            ) : (
              <Icon type={"close"} />
            )}
          </span>
        ),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: 150,
        render: (_, data) => (
          <>
            <Show when={"edit-station-connected"}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Button
                        onClick={() => {
                          this.openDrawer({
                            ...data,
                            connectedDate: moment(data.connectedDate),
                            disabled: !data.disabled,
                          });
                        }}
                        style={{ border: "none", boxShadow: "none" }}
                        ghost
                        type="primary"
                      >
                        <Icon type="edit" />
                        {freire(texts.STATION_EDIT)}
                      </Button>
                    </Menu.Item>
                    <Menu.Item>
                      <Popconfirm
                        placement="top"
                        title={freire(
                          texts.QUESTION_UPKEEP_STATUS(data.upkeep)
                        )}
                        okText={freire(texts.TOGGLE_UPKEEP_STATUS(data.upkeep))}
                        cancelText={freire(texts.CANCEL)}
                        onConfirm={() => this.onConfirmUpkeepStatus()}
                      >
                        <Button
                          style={{ border: "none", boxShadow: "none" }}
                          ghost
                          type="primary"
                          onClick={() =>
                            this.setState({
                              station: data,
                            })
                          }
                        >
                          <Icon type="tool" />
                          {freire(
                            data.upkeep
                              ? texts.NO_UPKEEP_STATUS
                              : texts.UPKEEP_STATUS
                          )}
                        </Button>
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  shape="round"
                  ghost
                  type="primary"
                  className="conected-stations-action-button"
                >
                  <Icon type="more" />
                  {freire(texts.OPTIONS)}
                </Button>
              </Dropdown>
            </Show>
            <Show when={"show-qrcode-station"}>
              <Button
                shape="round"
                ghost
                type="primary"
                className="conected-stations-action-button"
                onClick={() =>
                  this.showModalQrcode({
                    stationID: data.stationID,
                    name: data.name,
                    code: data.code,
                  })
                }
              >
                <Icon type="qrcode" /> {freire(texts.QRCODE)}
              </Button>
            </Show>
            <Button
              shape="round"
              ghost
              type="primary"
              className="conected-stations-action-button"
              onClick={() => this.showModalShareStation(data.stationID)}
            >
              <Icon type="share-alt" /> {freire(texts.SHARE)}
            </Button>
          </>
        ),
      },
    ];
  }

  getHeader() {
    const { freire } = this.context;
    return [
      { label: "ID", key: "_id" },
      { label: freire(texts.STATION_ID), key: "stationID" },
      { label: freire(texts.NAME), key: "name" },
      {
        label: freire(texts.COMPANY),
        key: "companyName",
      },
      { label: freire(texts.ADDRESS), key: "address" },
      { label: freire(texts.CITY), key: "address2.city" },
      { label: freire(texts.STATE), key: "address2.state" },
      { label: freire(texts.LATITUDE), key: "lat" },
      { label: freire(texts.LONGITUDE), key: "lng" },
      { label: freire(texts.TOTAL_CHARGED), key: "chargeTotal" },
      { label: freire(texts.PLUGS), key: "plugs" },
      {
        label: `${freire(texts.PAYMENT)} (${freire(texts.RESERVE)})`,
        key: "payment.enabled",
      },
      { label: freire(texts.RESERVE_VALUE), key: "payment.value" },
      {
        label: `${freire(texts.PAYMENT)} (${freire(texts.CHARGE)})`,
        key: "paymentCharge.enabled",
      },
      {
        label: freire(texts.METHOD_PGT_CHARGE),
        key: "paymentCharge.description",
      },
      { label: freire(texts.CHARGE_VALUE), key: "paymentCharge.value" },
      { label: freire(texts.ENABLED_DATE), key: "connectedDate" },
      { label: freire(texts.STATION_TYPE), key: "stationType" },
      { label: freire(texts.STATION_MODEL), key: "stationModel" },
      { label: freire(texts.STATION_LOCAL), key: "stationLocal" },
    ];
  }

  onChangeCompany = (value) => this.setState({ selectedCompanies: value });

  render() {
    const {
      visibleEditDrawer,
      loadingEditDrawer,
      station,
      companyID,
      pagination,
      loadingTable,
      dateFiltrada,
      totalDateFiltrada,
      companies,
      dataExcel,
      stationQrCode,
      visibleModalQrcode,
      companyOptions,
      stationsOptions,
      visibleShareStation,
      shareStationID,
    } = this.state;

    const { freire } = this.context;

    const columns = this.getColumns();

    const filterFields = generateFields({
      stationsOptions,
      companyOptions,
      onChangeCompany: this.onChangeCompany,
    });

    return (
      <div className="container">
        <div className="header">
          <Breadcrumbs
            breadcrumbs={[
              freire(texts.ADMIN),
              freire(texts.STATIONS),
              freire(texts.CONNECTED),
            ]}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Show when={"batch-operation"}>
              <Button
                icon="plus-circle"
                onClick={this.goBatchOperations}
                type="primary"
                style={{ marginRight: "10px" }}
              >
                {freire(texts.BATCH_OPERATIONS)}
              </Button>
            </Show>

            <Show when={"add-station-connected"}>
              <Button icon="plus" onClick={this.goNew} type="primary">
                Nova estação
              </Button>
            </Show>
          </div>
        </div>
        <GenerateFilter
          form={this.props.form}
          fields={filterFields}
          handleClear={() => {
            this.setState({ cardFilter: undefined }, () => {
              this.clearFilter();
              this.filter(true);
            });
          }}
          handleFilter={this.filter}
        />
        <CardStatusStations
          ref={this.CardStatusStations}
          companyID={companyID}
          texts={texts}
          filter={(stateName) => {
            this.setState({ cardFilter: stateName }, () => {
              this.clearFilter();
              this.getStations();
              this.setState((prevState) => ({
                pagination: { ...prevState.pagination, page: 1 },
              }));
            });
          }}
        />

        <TablePagination
          style={{ backgroundColor: "white" }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          loading={loadingTable}
          rowKey={(data) => data._id}
          dataSource={dateFiltrada}
          columns={
            this.props.hasPermission("filter-by-visibility-station")
              ? columns
              : columns.filter((col) => col.dataIndex !== "visibility") // oculta a coluna 'Visibilidade' para usuários que não são Admin Tupi
          }
          current={pagination.page}
          total={totalDateFiltrada}
          onPagination={(pagination) =>
            this.setState({ pagination }, () => this.filter(false))
          }
        />

        <EditDrawer
          visible={visibleEditDrawer}
          loading={loadingEditDrawer}
          onClose={() => this.closeDrawer()}
          onSave={(values) => this.onSalveStation(values)}
          station_id={station._id}
          companyID={companyID}
          companies={companies}
        />

        <div style={{ paddingBottom: 50, paddingTop: 8 }}>
          <div style={{ display: "none" }}>
            <CSVLink
              ref={this.CSVLinkRef}
              filename={freire(
                texts.CSV_FILE_NAME(moment().format(freire(texts.MONTH_TIME)))
              )}
              className="btn btn-primary"
              data={dataExcel}
              headers={this.state.headers}
            />
          </div>

          <ButtonCsvDownload
            loading={this.state.csvLoading}
            onClick={() => this.downloadExcel()}
          />
        </div>

        <Modal
          width="50%"
          destroyOnClose
          centered
          title={`${stationQrCode.name} (${stationQrCode.stationID})`}
          visible={visibleModalQrcode}
          onCancel={() => this.setState({ visibleModalQrcode: false })}
          footer={[
            <Button
              key="cancel"
              onClick={() => this.setState({ visibleModalQrcode: false })}
            >
              {freire(texts.CLOSE)}
            </Button>,
          ]}
        >
          <QrCode station={stationQrCode} />
        </Modal>
        <ShareStation
          visible={visibleShareStation}
          stationID={shareStationID}
          onClose={() => this.setState({ visibleShareStation: false })}
        />
      </div>
    );
  }
}

const PageConnectedListStations = Form.create({ name: "filters" })(
  ListConnectedStations
);
export default withPermission(withRouter(PageConnectedListStations));
